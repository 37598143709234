import "core-js/modules/esnext.iterator.map.js";
import { ref, onMounted, nextTick, getCurrentInstance, onBeforeUnmount, computed } from 'vue';
import { recordStartApi, recordAnswerApi, recordSubmitApi } from '@/api/test';
import { useRoute, useRouter } from 'vue-router';
import { getObsUrl } from '../utils/obsUrl';
import { ElMessage } from 'element-plus';
export default {
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const dialogVisible = ref(false);
    let questionsArr = ref([]);
    let recordArr = ref([]);
    let historyAnswer = ref('');
    let questionsIndex = ref(0);
    let radioArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    let radio = ref('');
    const todetail = route.query.todetail;
    const groupId = route.query.group;
    const _id = route.query._id;
    const recordId = ref('');
    recordId.value = route.query.recordId;
    const timeNum = ref(0);
    let timer;
    let timeNumTxt = computed(i => {
      const hours = Math.floor(timeNum.value % (60 * 60 * 24) / (60 * 60)) || 0;
      const minutes = Math.floor(timeNum.value % (60 * 60) / 60) || 0;
      const seconds = Math.floor(timeNum.value % 60) || 0;
      return `${hours < 10 ? '0' + hours : hours}: ${minutes < 10 ? '0' + minutes : minutes}: ${seconds < 10 ? '0' + seconds : seconds}`;
    });
    const continueConfirmedBool = ref();
    continueConfirmedBool.value = route.query.continueConfirmedBool ? true : false;
    const changeIndex = i => {
      dialogVisible.value = false;
      questionsIndex.value = i;
      radio.value = '';
      const arr = recordArr.value;
      if (arr[i].answer && arr[i].upBool) {
        radio.value = arr[i].answer;
      } else {
        radio.value = '';
      }
      upMathJax();
    };
    const upMathJax = item => {
      nextTick(() => {
        MathJax.texReset();
        MathJax.typesetClear();
        MathJax.typesetPromise();
      });
    };
    const upDateQuestions = value => {
      radio.value = value;
      const arr = recordArr.value;
      const i = questionsIndex.value;
      recordAnswerApi({
        _id: recordId.value,
        // 记录ID
        question: arr[i].question,
        // 问题ID
        answer: value // 回答
      }).then(res => {
        if (res.success) {
          arr[i] = Object.assign({}, arr[i], {
            upBool: true,
            answer: value
          });
        }
      });
    };
    const nextClick = item => {
      const i = questionsIndex.value + 1;
      changeIndex(i);
    };
    const recordSubmit = item => {
      const arr = recordArr.value;
      const i = questionsIndex.value;
      recordSubmitApi({
        _id: recordId.value,
        // 记录ID
        question: arr[i].question,
        // 问题ID
        answer: radio.value // 回答
      }).then(res => {
        if (res.success) {
          router.replace({
            // name: todetail ? 'resultdetail' : 'result',
            name: 'resultdetail',
            query: {
              _id: recordId.value
            }
          });
        }
      });
    };

    // const continueFun = () => {
    //   ElMessageBox.confirm(
    //     '上一次测验尚未结束，是否继续未结束的测验？',
    //     '提示',
    //     {
    //       confirmButtonText: '继续开始',
    //       cancelButtonText: '重新开始',
    //       type: 'warning'
    //     }
    //   )
    //     .then(() => {
    //       continueConfirmedBool.value = true
    //       testInit()
    //     })
    //     .catch(() => {
    //       continueConfirmedBool.value = false
    //       testInit()
    //     })
    // }
    const testInit = item => {
      clearInterval(timer);
      recordStartApi({
        group: groupId,
        _id: _id
      }).then(res => {
        if (res.success) {
          if (res.result.record.submitAt) {
            router.replace({
              // name: todetail ? 'resultdetail' : 'result',
              name: 'resultdetail',
              query: {
                _id: res.result.record._id
              }
            });
            return false;
          }
          const interval = res.result.duration - (+proxy.$dayjs() - +proxy.$dayjs(res.result.record.startAt)) / 1000;
          timeNum.value = interval < 1 ? 0 : interval;
          questionsArr.value = res.result.questions;
          recordArr.value = res.result.record.answers.map(e => {
            return Object.assign({}, e, {
              upBool: e.answer ? true : false
            });
          });
          radio.value = recordArr.value[questionsIndex.value]?.answer;
          recordId.value = res.result.record._id;
          upMathJax();
          if (timeNum.value > 0) {
            timer = setInterval(() => {
              if (timeNum.value < 1) {
                clearInterval(timer);
                ElMessage.error('考试结束，自动提交考卷');
                setTimeout(() => {
                  recordSubmit();
                }, 500);
              } else {
                timeNum.value = timeNum.value - 1;
              }
            }, 1000);
          }
        }
        if (res.error?.code === 'error.paper_is_not_free') return setTimeout(() => router.back(), 1000);
        if (res.error?.code == 'error.record_exists') {
          // ElMessage.error('测试已结束')
          // router.back()
          router.replace({
            name: 'home'
          });
        }
      });
    };
    onMounted(() => {
      testInit();
    });
    onBeforeUnmount(() => {
      clearInterval(timer);
    });
    const returnImgUrl = url => {
      return getObsUrl(url);
    };
    return {
      // quesRef,
      // answerRef,
      dialogVisible,
      timeNum,
      timeNumTxt,
      timer,
      radio,
      radioArr,
      questionsArr,
      questionsIndex,
      recordArr,
      returnImgUrl,
      changeIndex,
      upDateQuestions,
      nextClick,
      recordSubmit
    };
  }
};