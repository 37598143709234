import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue";
import _imports_0 from '../assets/amcbg.jpg';
import _imports_1 from '../assets/wxqr.jpg';
import _imports_2 from '../assets/banner.jpg';
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "right-box"
};
const _hoisted_3 = {
  class: "group"
};
const _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_cache[2] || (_cache[2] = _createStaticVNode("<div class=\"left-box\" data-v-7261dfbe><img class=\"top-img\" src=\"" + _imports_0 + "\" data-v-7261dfbe><div class=\"info-box\" data-v-7261dfbe><h3 data-v-7261dfbe>2025年AMC8中国区</h3><label data-v-7261dfbe>报名截止时间</label><div data-v-7261dfbe>2025年1月12日</div><div data-v-7261dfbe></div><label data-v-7261dfbe>竞赛时间</label><div data-v-7261dfbe>2025年1月23日 周五</div><div data-v-7261dfbe>10:00-10:40(中国时间)</div><label data-v-7261dfbe>竞赛资格</label><div data-v-7261dfbe>8年级且14.5岁以下</div></div><div class=\"qrcode\" data-v-7261dfbe><img src=\"" + _imports_1 + "\" data-v-7261dfbe><div data-v-7261dfbe>扫码咨询</div></div></div>", 1)), _createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("img", {
    class: "course",
    src: _imports_2
  }, null, -1)), _cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "title"
  }, "备赛冲刺营2025", -1)), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.paperList, (item, idx) => {
    return _openBlock(), _createElementBlock("div", {
      class: "group-item",
      key: item._id
    }, [_createElementVNode("div", {
      style: _normalizeStyle({
        backgroundColor: $setup.colors[idx % $setup.colors.length]
      }),
      onClick: $event => $setup.groupTap(item, idx)
    }, [_createElementVNode("label", null, _toDisplayString(item.name), 1)], 12, _hoisted_4)]);
  }), 128))])])])]);
}