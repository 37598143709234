import { ref, onMounted, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import { paperlist } from '@/api/home';
import { userStore } from '@/store/index';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const store = userStore();
    const paperList = ref([]);
    const colors = '#FFB265 #F9686B #9DC3FA #A48BCF'.split(/ /);
    const groupTap = (item, idx) => {
      if (!store.info) return ElMessage.error('请先登录'), router.push('/login');
      if (!store.info.grade?.value) return ElMessage.error('请先完善信息'), router.push('/user');

      // if (item._id === '6670db62f414742691f0702c') {
      //   if (item.record?.submitAt) return router.push('/resultdetail?_id=' + item.record._id)
      //   else return router.push({ name: 'test', query: { group: item._id, recordId: item.record?._id } })
      // }
      router.push(`/papers?group=${item._id}&t=${idx}`);
    };
    onMounted(() => {
      paperlist().then(res => {
        if (res.success) {
          paperList.value = res.result;
        }
      });
    });
    return {
      groupTap,
      paperList,
      colors
    };
  }
};